import React from "react"
import { Link } from "gatsby"
import Signature from "../images/components/signature.svg"

const Header = class extends React.Component {
  render() {
    return (
      <header className="header">
        <Link
          className="header__link header__link--home"
          activeClassName="header__link--active"
          to="/"
        >
          <Signature />
        </Link>
        <nav className="header__navigation">
          <ul>
            <li>
              <Link
                className="header__link"
                activeClassName="header__link--active"
                to="/work"
              >
                <span className="header__sublink--primary">
                  Travail en cours
                </span>
                <span className="header__sublink--secondary">Latest work</span>
              </Link>
            </li>
            <li>
              <Link
                className="header__link"
                activeClassName="header__link--active"
                to="/contact"
              >
                <span className="header__sublink--primary">
                  Atelier - expositions & résidence
                </span>
                <span className="header__sublink--secondary">
                  Studio - exhibitions & residency
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="header__link"
                activeClassName="header__link--active"
                to="/exhibition"
              >
                <span className="header__sublink--primary">
                  Expositions - galeries & centres d'art
                </span>
                <span className="header__sublink--secondary">
                  Exhibitions - galleries & art centers
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="header__link"
                activeClassName="header__link--active"
                to="/works"
              >
                <span className="header__sublink--primary">Œuvres</span>
                <span className="header__sublink--secondary">
                  Previous work
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="header__link"
                activeClassName="header__link--active"
                to="/about"
              >
                <span className="header__sublink--primary">Biographie</span>
                <span className="header__sublink--secondary">Biography</span>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header
