import React from "react"
import Helmet from "react-helmet"
import Header from "./Header"
import "../styles/main.scss"
import useSiteMetadata from "./SiteMetadata"

const Layout = ({
  children,
  modal = false,
  modalTitle,
  scroll = false,
  className = ""
}) => {
  const { title, description, keywords } = useSiteMetadata()
  const handleMove = event => {
    const root = event.currentTarget
    const x =
      event.type === "touchmove" ? event.touches[0].pageX : event.clientX
    const y =
      event.type === "touchmove" ? event.touches[0].pageY : event.clientY

    root.style.setProperty("--mouse-x", x + "px")
    root.style.setProperty("--mouse-y", y + "px")
  }

  if (modal) {
    return (
      <>
        <Helmet>
          <html lang="en" />
          <meta charset="utf-8" />
          <title>{`${title} — ${modalTitle}`}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, viewport-fit=cover"
          />
        </Helmet>
        <div className="modal">{children}</div>
      </>
    )
  } else {
    return (
      <div className="root" onMouseMove={handleMove} onTouchMove={handleMove}>
        <Helmet>
          <html lang="en" />
          <meta charset="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, viewport-fit=cover"
          />
        </Helmet>
        <Header />
        <main className="main">
          <div
            className={`${className} content ${
              scroll ? "content--scroll" : "content--full"
            }`}
          >
            {children}
          </div>
        </main>
      </div>
    )
  }
}

export default Layout
